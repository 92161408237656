import React from 'react';
import isEqual from 'lodash.isequal';
import { BrowsePageOutput } from 'js/model/rainbow/browse-page/BrowsePageOutput';
import { fetchBrowse } from 'js/service/browseService';
import { VenueListingCriteria } from 'js/model/rainbow/browse-page/VenueListingCriteria';
import {
  parseUri,
  generateUri,
  windowLocationQueryParameters,
} from 'js/helpers/uri-util';
import { Location } from 'js/model/rainbow/browse-page/VenueListingSpecificationOutput';
import { getCurrentPosition } from 'js/helpers/geo-location';
import { setHost } from 'js/helpers/browse-uris';
import { trackEvent, push } from 'js/helpers/google-tag-manager';
import { browsePageDataLayer } from 'js/model/tracking/browse';
import { historyPush } from 'js/helpers/history';
import * as navigationLocation from 'js/components/Navigation/navigation-location';
import { Context } from 'js/components/LocaleWrapper';
import { yieldToMain } from 'js/common/yield-to-main';
import { storage } from '@treatwell/ui';
import { ViewMode, viewModeForValue } from './ViewModeToggle';
import { getPageSize } from './page-size';
import { trackSearch } from './tracking/trackSearch';

export enum browseRemovalParameterType {
  DATE = 'availableOn',
  STARTHOURS = 'timeRange.from',
  ENDHOURS = 'timeRange.to',
  NIGHTS = 'fixedStayNights',
  MAXDURATION = 'maxServiceDuration',
  ACCOLADE = 'accolade',
  BOOKINGTYPE = 'bookingType',
  PRICERANGEFROM = 'priceRange.from',
  PRICERANGETO = 'priceRange.to',
  VENUETYPE = 'venueType',
  TREATMENT = 'treatments',
  TREATMENTTYPE = 'treatmentType',
  AMENITIES = 'amenities',
  BRANDS = 'brands',
}

export enum AddLocation {
  Geo,
  CurrentPage,
  CurrentPageWithRadius,
}

export type ChangeBrowseDataFunction = (
  parameters: VenueListingCriteria,
  removeParameters?: browseRemovalParameterType[],
  addLocation?: AddLocation,
  triggeredByHistoryNavigation?: boolean,
  viewMode?: ViewMode,
  aliasId?: number
) => Promise<void>;

export const changeBrowseDataContext = React.createContext<
  ChangeBrowseDataFunction
>(() => Promise.resolve());

interface Props {
  initialBrowsePageOutput: BrowsePageOutput;
  children: (
    browsePageOutput: BrowsePageOutput,
    loading: boolean
  ) => React.ReactNode;
}

interface State {
  browsePageOutput: BrowsePageOutput;
  loading: boolean;
  currentTreatmentTypeParameters: CurrentTreatmentTypeParameters;
}

interface CurrentTreatmentTypeParameters {
  treatmentCategoryIds?: number[];
  treatmentCategoryGroupId?: number;
  venueTypeId?: number;
  menuItemTypes?: string[];
  nights?: number;
}

export class BrowseData extends React.Component<Props, State> {
  static contextType = Context;

  declare context: React.ContextType<typeof Context>;

  constructor(props: Props, context: unknown) {
    super(props, context);

    this.transformedBrowseData(props.initialBrowsePageOutput);

    this.state = {
      browsePageOutput: props.initialBrowsePageOutput,
      loading: false,
      currentTreatmentTypeParameters: this.getInitialTreatmentTypeParameters(),
    };
  }

  public componentDidMount(): void {
    window.addEventListener('popstate', this.onPopState);

    const treatmentAliasIdFromHomePage = storage.session.getItem(
      'searchAliasTreatment'
    );

    trackSearch(this.context.channel.country.countryCode, {
      aliasId: treatmentAliasIdFromHomePage || undefined,
      ...this.state.browsePageOutput,
    });

    storage.session.removeItem('searchAliasTreatment');
    // needs to be removed so that if a user refreshes the browse page in the same session or use the nav bar this will be sent incorrectly
  }

  public componentWillUnmount(): void {
    window.removeEventListener('popstate', this.onPopState);
  }

  private onPopState = (): void => {
    this.changeBrowseData({}, [], undefined, true);
  };

  private async trackNewBrowsePage(browsePageOutput: BrowsePageOutput) {
    await yieldToMain();

    // Clear any old venueIds to avoid undesired merges
    push({
      listing: {
        venues: undefined,
      },
    });

    trackEvent('virtualPageView', {
      page: {
        path: window.location.pathname,
      },
      ...browsePageDataLayer(browsePageOutput, this.context.pageData.channel)
        .pageSpecific,
    });

    trackSearch(this.context.channel.country.countryCode, browsePageOutput);
  }

  private currentPageLocationAsParameter(
    currentLocation: Location
  ): VenueListingCriteria['location'] {
    if (currentLocation.tree) {
      return {
        tree: {
          id: currentLocation.tree.id,
        },
      };
    }

    if (currentLocation.point) {
      return {
        point: {
          latitude: currentLocation.point.lat,
          longitude: currentLocation.point.lon,
        },
      };
    }

    if (currentLocation.external) {
      return {
        external: {
          id: currentLocation.external.id,
          description: currentLocation.external.name,
        },
      };
    }

    if (currentLocation.postalReference) {
      return {
        postalReferenceId: currentLocation.postalReference.id,
      };
    }

    throw new Error('Unhandled location type');
  }

  private removeParameters(
    currentPath: string,
    removeParameters?: browseRemovalParameterType[]
  ): string {
    if (!removeParameters || removeParameters.length === 0) {
      return currentPath;
    }

    // NOTE - This is special case when we want to clear some parameters from new browse data request

    // 1) construct uri path from current pathname
    const { code, languageCode } = this.context.pageData.channel;
    const parsedUri = parseUri(currentPath, code, languageCode);
    if (!parsedUri) {
      return currentPath;
    }

    // 2) remove parameters
    for (const parameter of removeParameters) {
      delete parsedUri.values[parameter];
    }

    // 3) generate new uri path
    return (
      generateUri(
        'browse',
        parsedUri.values,
        this.context.pageData.channel.code,
        this.context.pageData.channel.languageCode
      ) || currentPath
    );
  }

  private async transformParametersWithOptions(
    parameters: VenueListingCriteria,
    addLocation?: AddLocation,
    triggeredByHistoryNavigation?: boolean
  ): Promise<VenueListingCriteria> {
    const currentLocation = this.state.browsePageOutput.specification.location;
    const newParameters: typeof parameters = { ...parameters };

    // Add current page location used for radius changes as API requires location + radius
    if (addLocation === AddLocation.CurrentPage && currentLocation) {
      newParameters.location = {
        ...newParameters.location,
        ...this.currentPageLocationAsParameter(currentLocation),
      };
    }

    // Non-location change keeps location and radius
    if (
      addLocation === AddLocation.CurrentPageWithRadius &&
      currentLocation &&
      currentLocation.radius
    ) {
      newParameters.location = {
        ...newParameters.location,
        ...this.currentPageLocationAsParameter(currentLocation),
        radius: currentLocation.radius.distance,
      };
    }

    // Add current geo location
    if (addLocation === AddLocation.Geo) {
      newParameters.location = {
        ...(await currentGeoLocationAsParameter()),
      };
    }

    // All operations other than pagination and history changes should reset the page to 0
    if (!newParameters.page && !triggeredByHistoryNavigation) {
      newParameters.page = 0;
    }

    return newParameters;
  }

  // If there's no location, use the channel's country location.
  // This ensures that there's something to show in the location input, which
  // also then acts as a hint to the user that the location can be refined.
  //
  // There can be no location for some browse pages.
  // A tag page is the primary example of this.
  private transformedBrowseData(browsePageOutput: BrowsePageOutput): void {
    if (
      !browsePageOutput.specification ||
      browsePageOutput.specification.location
    ) {
      return;
    }

    const channelLocation = this.context.channel.country;

    browsePageOutput.specification.location = { tree: channelLocation };
    browsePageOutput.aggregations = browsePageOutput.aggregations || {};
    browsePageOutput.aggregations.locations =
      browsePageOutput.aggregations.locations || [];
    browsePageOutput.aggregations.locations.unshift({
      ...channelLocation,
      count: 0,
    });
  }

  private persistNavigationLocation(browsePageOutput: BrowsePageOutput): void {
    const { location } = browsePageOutput.specification || {
      location: undefined,
    };
    if (!location) {
      return;
    }

    const languageCode = this.context.pageData.channel.languageCode;
    if (location.tree) {
      navigationLocation.removeAllExcept(languageCode);
      navigationLocation.save(
        { location: location.tree.normalisedName },
        languageCode
      );
    }
    if (location.postalReference) {
      navigationLocation.removeAllExcept(languageCode);
      navigationLocation.save(
        {
          postalReference: location.postalReference.normalisedName,
        },
        languageCode
      );
    }
    if (location.external) {
      navigationLocation.removeAllExcept(languageCode);
      navigationLocation.save(
        {
          externalLocation: location.external.id,
        },
        languageCode
      );
    }
    if (location.point) {
      navigationLocation.removeAllExcept(languageCode);
      navigationLocation.save(
        {
          searchAreaGeocode: `${location.point.lat},${location.point.lon}`,
        },
        languageCode
      );
    }
  }

  private changeBrowseData: ChangeBrowseDataFunction = async (
    parameters,
    removeParameters,
    addLocation?: AddLocation,
    triggeredByHistoryNavigation = false,
    viewMode?: ViewMode,
    aliasId?: number
  ) => {
    let newViewMode = viewMode;
    if (triggeredByHistoryNavigation) {
      const navigationViewMode = this.viewModeFromWindowLocation();
      if (navigationViewMode !== this.state.browsePageOutput.viewMode) {
        newViewMode = navigationViewMode;
      }
    }

    if (newViewMode) {
      await this.fetchNewData(
        { pageSize: getPageSize(newViewMode) },
        undefined,
        undefined,
        triggeredByHistoryNavigation,
        newViewMode,
        aliasId
      );
    } else {
      let parametersToRemove = removeParameters ? [...removeParameters] : [];
      if (this.shouldClearPriceRange(parameters)) {
        parametersToRemove = [
          ...parametersToRemove,
          browseRemovalParameterType.PRICERANGEFROM,
          browseRemovalParameterType.PRICERANGETO,
        ];
      }

      await this.fetchNewData(
        {
          ...parameters,
          pageSize: getPageSize(this.state.browsePageOutput.viewMode),
        },
        parametersToRemove,
        addLocation,
        triggeredByHistoryNavigation,
        undefined,
        aliasId
      );
    }
  };

  private viewModeFromWindowLocation(): ViewMode | undefined {
    const queryParameters = windowLocationQueryParameters();
    return viewModeForValue(queryParameters.view);
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    const { loading, browsePageOutput } = this.state;

    return (
      <changeBrowseDataContext.Provider value={this.changeBrowseData}>
        {children(browsePageOutput, loading)}
      </changeBrowseDataContext.Provider>
    );
  }

  private async fetchNewData(
    parameters: VenueListingCriteria,
    removeParameters?: browseRemovalParameterType[],
    addLocation?: AddLocation,
    triggeredByHistoryNavigation?: boolean,
    viewMode?: ViewMode,
    aliasId?: number
  ): Promise<void> {
    try {
      this.setState({ loading: true });

      const newViewMode = triggeredByHistoryNavigation
        ? this.viewModeFromWindowLocation()
        : viewMode;

      const currentPath = this.removeParameters(
        window.location.pathname,
        removeParameters
      );
      const transformedParameters = await this.transformParametersWithOptions(
        parameters,
        addLocation,
        triggeredByHistoryNavigation
      );

      const browsePageOutput = await fetchBrowse(
        this.context.pageData,
        currentPath,
        undefined,
        transformedParameters
      );
      this.transformedBrowseData(browsePageOutput);
      this.persistNavigationLocation(browsePageOutput);

      const historyUrl = new URL(
        setHost(
          browsePageOutput.canonicalUri,
          window.location.protocol,
          window.location.host
        ) + window.location.search
      );

      if (newViewMode) {
        if (newViewMode !== ViewMode.Hybrid) {
          historyUrl.searchParams.append('view', newViewMode);
        } else {
          historyUrl.searchParams.delete('view');
        }
      }

      if (
        !triggeredByHistoryNavigation &&
        historyUrl.toString() !== window.location.href
      ) {
        historyPush({}, '', historyUrl);
      }

      browsePageOutput.aliasId = aliasId;

      this.setState(
        {
          browsePageOutput: {
            ...browsePageOutput,
            viewMode:
              newViewMode ||
              browsePageOutput.viewMode ||
              this.viewModeFromWindowLocation(),
          },
          loading: false,
        },
        () => this.trackNewBrowsePage(browsePageOutput)
      );

      window.scroll(0, 0);
    } catch (e) {
      this.setState({ loading: false });
      throw e;
    }
  }

  private getInitialTreatmentTypeParameters(): CurrentTreatmentTypeParameters {
    const {
      initialBrowsePageOutput: { specification },
    } = this.props;

    let initialTreatmentTypeParameters = {};

    if (specification.treatmentCategories) {
      initialTreatmentTypeParameters = {
        ...initialTreatmentTypeParameters,
        treatmentCategoryIds: specification.treatmentCategories.map(
          category => category.id
        ),
      };
    }
    if (specification.treatmentCategoryGroup) {
      initialTreatmentTypeParameters = {
        ...initialTreatmentTypeParameters,
        treatmentCategoryGroupId: specification.treatmentCategoryGroup.id,
      };
    }
    if (specification.venueType) {
      initialTreatmentTypeParameters = {
        ...initialTreatmentTypeParameters,
        venueTypeId: specification.venueType.id,
      };
    }
    if (specification.menuItemTypes) {
      initialTreatmentTypeParameters = {
        ...initialTreatmentTypeParameters,
        menuItemTypes: specification.menuItemTypes,
        nights: specification.nights,
      };
    }
    return initialTreatmentTypeParameters;
  }

  public shouldClearPriceRange(parameters: VenueListingCriteria): boolean {
    const { currentTreatmentTypeParameters } = this.state;
    const treatmentTypeParameterKeys: (keyof CurrentTreatmentTypeParameters)[] = [
      'venueTypeId',
      'treatmentCategoryIds',
      'treatmentCategoryGroupId',
      'menuItemTypes',
    ];

    let newTreatmentTypeParameters = { ...currentTreatmentTypeParameters };

    for (const key of treatmentTypeParameterKeys) {
      if (
        parameters[key] &&
        !isEqual(parameters[key], currentTreatmentTypeParameters[key])
      ) {
        newTreatmentTypeParameters = {
          ...newTreatmentTypeParameters,
          [key]: parameters[key],
        };
      }
    }

    if (parameters.menuItemTypes) {
      newTreatmentTypeParameters = {
        ...newTreatmentTypeParameters,
        nights: parameters.nights,
      };
    }

    if (!isEqual(currentTreatmentTypeParameters, newTreatmentTypeParameters)) {
      this.setState({
        currentTreatmentTypeParameters: newTreatmentTypeParameters,
      });
      return true;
    }
    return false;
  }
}

export async function currentGeoLocationAsParameter(): Promise<
  VenueListingCriteria['location']
> {
  const geoLocation = await getCurrentPosition(10 * 1000);

  return {
    point: {
      latitude: geoLocation.coords.latitude,
      longitude: geoLocation.coords.longitude,
    },
  };
}

BrowseData.contextType = Context;
